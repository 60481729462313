function Alimentar() {
  return (
    <>
      <article>
        <h2>
          Transferencias <strong>CBU y CVU</strong>
        </h2>
        <p>
          Envía y recibí dinero de quien quieras, de manera segura, inmediata y
          desde cualquier cuenta bancaria o billetera virtual.
        </p>

        <h3>Beneficios</h3>
        <p>Usá tu dinero en cuenta para transferir gratis y al instante.</p>
      </article>
      <article className="tarImage">
        <img src="../assets/tarifas-06.png" alt="transferncias" />
      </article>
    </>
  );
}

export default Alimentar;
