function Extracash() {
  return (
    <>
      <article>
        <h2>
          Retiro de <strong>Efectivo</strong>
        </h2>
        <p>
          Encontrá un servicio adicional para tus clientes y generá ingresos
          extras en tu comercio. Con Yopit Pago, podrás ofrecer el servicio de
          retiro de efectivo, reducir costos y aumentar tus ganancias.
        </p>

        <h3>Tarifas</h3>
        <table>
          <thead>
            <tr>
              <th>Recibís</th>
              <th>Costo para el cliente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$75 (*)</td>
              <td>$150 (*)</td>
            </tr>
          </tbody>
        </table>
        <p className="referenceText">(*) Incluye IVA</p>

        <h3>Beneficios</h3>
        <p>
          Reducí los costos asociados al manejo de dinero en efectivo. Evitá los
          gastos de transporte y seguridad, y optimizá tus recursos financieros.
        </p>
      </article>
      <article className="tarImage">
        <img src="../assets/tarifas-01.png" alt="" />
      </article>
    </>
  );
}

export default Extracash;
