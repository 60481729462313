function Pos() {
  return (
    <>
      <article>
        <h2>
          Terminal <strong>MPOS</strong>
        </h2>
        <p>
          Descubrí la comodidad de cobrar con tarjeta de débito y acreditar las
          ventas directamente en tu cuenta a través de nuestro lector. Además,
          podrás ofrecer a tus clientes la opción de retirar efectivo. Es muy
          fácil de usar, se conecta mediante bluetooth a tu celular.
        </p>

        <h3>Beneficios:</h3>
        <p>
          Aceptá todas las tarjetas y obtené el dinero al instante. Tené tu Mpos
          sin ningún costo adicional.
        </p>
      </article>
      <article className="tarImage">
        <img src="../assets/tarifas-02.png" alt="POS" />
      </article>
    </>
  );
}

export default Pos;
