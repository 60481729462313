function Linkdepago() {
    return (
        <>
            <article>
                <h2><strong>Link</strong> <br/>de Pago</h2>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque ipsa ipsam, fuga natus nesciunt libero! Amet facilis laboriosam vitae dignissimos reprehenderit nam totam atque rerum, laudantium expedita similique in deleniti.
                </p>

                <h3>Tarifas</h3>
                <table> 
                    <thead>
                        <tr>
                            <th>Cuotas</th>
                            <th>Comisión</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td>1-3</td>
                            <td>4%</td>
                        </tr>
                        <tr>
                            <td>4-6</td>
                            <td>8%</td>
                        </tr>
                        <tr>
                            <td>+6</td>
                            <td>10%</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Beneficios:</h3>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil cupiditate, tempora laudantium laborum labore, iure, quos voluptates molestias dolores magnam dignissimos hic explicabo eaque ipsam harum fugit aliquid recusandae quibusdam?
                </p>
            </article>
            <article className="tarImage">
                <img src="../assets/tarifas-08.png" alt="" />
            </article>
        </>
    );
}

export default Linkdepago;