const Whatsapp = (props) => {
    return (
        <>
            <a href="https://wa.me/541136947035" target="_blank" className="cta-w">
                <img src="./assets/whatsapp.png" alt="Whatsapp" />
            </a>
        </>
    );
}
 
export default Whatsapp;