import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import Tarifas from './components/routes/Tarifas';
import Debito from './components/routes/Debito';
import Pos from './components/routes/Pos';
import RechargeAndServices from './components/routes/RechargeAndServices';
import Credito from './components/routes/Credito';
import Qr from './components/routes/Qr';
import Extracash from './components/routes/Extracash';
import Alimentar from './components/routes/Alimentar';
import Transferencia from './components/routes/Transferencia';
import Recibosdigitales from './components/routes/Recibosdigitales';
import Linkdepago from './components/routes/Linkdepago';
// import Formacion from './components/routes/formacion/Formacion';
// import Login from './components/routes/formacion/Login'
// import Ranking from './components/routes/formacion/Ranking';
// import Trivia from './components/routes/formacion/Trivia';
// import Home from './components/routes/formacion/Home';

import TerminosYCondiciones from './views/TerminosYCondiciones'
import PoliticaDePrivacidad from './views/PoliticaDePrivacidad';

ReactDOM.render(
    <BrowserRouter>
      <Routes>
        
        {/* <Route path='/formacion/' element={<Formacion />}>
          <Route index replace element={<Navigate to="/formacion/login" />}></Route>
          <Route path='home' element={<Home/>}></Route>
          <Route path='login' element={<Login/>}></Route>
          <Route path='ranking' element={<Ranking/>}></Route>
          <Route path='trivia' element={<Trivia/>}></Route>          
          <Route path='*' replace element={<Navigate to="/formacion/login" />} ></Route>
        </Route> */}

        <Route path="/tarifas/" element={<Tarifas/>}>
          <Route index  replace element={<Navigate to="/tarifas/debito" />}></Route>          
          
          <Route path="qr/" element={<Qr/>}></Route>          
          <Route path="transferencia/" element={<Transferencia/>}></Route>          
          <Route path="pos/" element={<Pos/>}></Route> 
          <Route path="rechargeandservices/" element={<RechargeAndServices/>}></Route> 
          <Route path="debito/" element={<Debito/>}></Route>     
          <Route path="extracash/" element={<Extracash/>}></Route>     
          

          <Route path="credito/" element={<Credito/>}></Route>  
          <Route path="alimentar/" element={<Alimentar/>}></Route>          
          <Route path="recibosdigitales/" element={<Recibosdigitales></Recibosdigitales>}></Route>          
          <Route path="linkdepago/" element={<Linkdepago></Linkdepago>}></Route>          
          <Route path='*' replace element={<Navigate to="/tarifas/debito" />} ></Route>
        </Route>
        
        <Route index path="/terminosycondiciones/" element={<TerminosYCondiciones />}></Route>        
        <Route index path="/politicadeprivacidad/" element={<PoliticaDePrivacidad />}></Route>        

        <Route index path="/" element={<App />}></Route>        
        <Route path="*" replace element={<Navigate to="/" replace />}></Route>
        
      </Routes>  
    </BrowserRouter>
  
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
