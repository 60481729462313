function RechargeAndServices() {
  return (
    <>
      <article>
        <h2>
          Pago de <strong>Servicios</strong> y <strong>Recargas</strong>
        </h2>
        <p>Pagá más de 6.000 servicios, recargá tu celular y tarjeta de
          transporte. Atraé nuevos clientes y aumentá tus ingresos.</p>

        {/* Services */}
        <h3>
          Tarifas <small> Cobro de Impuestos y servicio </small>
        </h3>
        <table>
          <thead>
            <tr>
              <th>Recibís (CAUS)</th>
              <th>Costo para el cliente (CAUS)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$75 (*)</td>
              <td>$150 (*)</td>
            </tr>
            <tr>
              <td rowspan="3" className="bg-selected">
                Recibís (por transacción)
              </td>
              <td>1 a 500 tx $7,50 + IVA</td>
            </tr>
            <tr>
              <td>501 a 1000 tx $10 + IVA</td>
            </tr>
            <tr>
              <td>1001 en adelante tx $12,50 + IVA</td>
            </tr>
          </tbody>
        </table>
        <p className="referenceText">(*) Incluye IVA</p>

        {/* Recharge */}
        <h3>
          Tarifas <small> Recarga de servicio </small>
        </h3>
        <table>
          <thead>
            <tr>
              <th>Recibís (CAUS)</th>
              <th>Costo para el cliente (CAUS)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$75 (*)</td>
              <td>$150 (*)</td>
            </tr>
            <tr>
              <td rowspan="3" className="bg-selected">
                Recibís (por importe de transacción)
              </td>
              <td>Telefónicas 2% + IVA</td>
            </tr>
            <tr>
              <td>DirecTV 1% + IVA</td>
            </tr>
            <tr>
              <td>Transporte 0,5% + IVA</td>
            </tr>
          </tbody>
        </table>
        <p className="referenceText">(*) Incluye IVA</p>

        <h3>Beneficios</h3>
        <p>
          Cada vez que realices un cobro de impuesto o recarga de servicio, el
          cliente abonará un adicional. Este monto adicional será de $150,
          dependiendo del caso, y lo mejor de todo es que el 50% de esa cantidad
          se otorgará como comisión directamente a tu comercio. ¡Sí, leíste
          bien!
        </p>
        <p>
          Pero eso no es todo. Además de la comisión, por cada transacción de
          cobro de impuestos, recibirás un plus adicional. Y en el caso de
          las recargas de servicio, recibirás un porcentaje del importe de la
          transacción como beneficio extra.
        </p>
        <p>
          Es importante destacar que las comisiones y beneficios serán
          liquidados de manera mensual, para que puedas tener un flujo constante
          de ingresos.
        </p>
      </article>
      <article className="tarImage">
        <img src="../assets/tarifas-07.png" alt="" />
      </article>
    </>
  );
}

export default RechargeAndServices;
