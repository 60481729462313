function Qr() {
  return (
    <>
      <article>
        <h2>
        Cobro con <strong>QR</strong> Interoperable
        </h2>
        <p>
          Podrás imprimir y exhibir el QR en tu local utilizando el material POP
          que te enviaremos. Tus clientes solo tendrán que escanear el código,
          ingresar el monto y pagar en cuestión de segundos. ¡Y lo mejor de todo
          es que también podrán hacerlo desde la app de otras billeteras
          virtuales o bancos!
        </p>

        <h3>Tarifas</h3>
        <table>
          <thead>
            <tr>
              <th>Pagás</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0,8% + IVA</td>
            </tr>
          </tbody>
        </table>
        <h3>Beneficios</h3>
        <p>
          Recibí al instante tus cobros y, además, podrás disfrutar de una
          comisión del 0% durante los primeros 3 meses.
        </p>
      </article>
      <article className="tarImage">
        <img src="../assets/tarifas-04.png" alt="" />
      </article>
    </>
  );
}

export default Qr;
