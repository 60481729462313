import React from 'react';

import '../shared/styles/styles.css';

const PoliticaDePrivacidad = () => {
  return (
    <div className="doc_container">
      <h1 className="title">POLÍTICA DE PRIVACIDAD</h1>

      <h2 class="subtitle">
        1. <u>INTRODUCCIÓN:</u>
      </h2>
      <p>
        1.1. La presente Política de Privacidad (en adelante la “
        <b>Política de Privacidad</b>”) se aplica a la utilización de la
        aplicación de software (en adelante, “<b>Aplicación</b>”) disponible en
        las tiendas de aplicaciones Appstore de Apple Inc. y Google Play de
        Google, Inc. y la plataforma “on-line” publicada en la URL:
        www.yopitpago.com (en adelante, el “<b>Sitio</b>”), provistas por la
        sociedad BENKOPAY SA (en adelante, la “<b>Sociedad</b>”), cuya función
        principal consiste en brindar un servicio (en adelante, el “
        <b>Servicio</b>”) de procesamiento de pagos que le permite a los
        Usuarios efectuar operaciones comerciales a través de la tecnología de
        código QR, a fin de pagar o percibir determinados montos por cuenta y
        orden del Usuario a través de la Aplicación y/o el Sitio-, así como la
        utilización de las distintas herramientas disponibles dentro del
        Servicio. En caso de ser necesario, la Sociedad podrá complementar la
        Política de Privacidad con información y/o términos y condiciones
        específicos con relación al Servicio.
      </p>

      <p>
        El mero registro en la Aplicación y/o en el Sitio atribuye la condición
        de usuario - (en adelante el “<b>Usuario</b>” o los “<b>Usuarios</b>”) y
        expresa la aceptación plena, voluntaria, informada y sin reservas de
        todas y cada una de las cláusulas de la Política de Privacidad en la
        versión publicada por la Sociedad en el momento mismo en que el Usuario
        acceda a la Aplicación y/o al Sitio o utilice su Servicio. En
        consecuencia, la Política de Privacidad constituirá un acuerdo válido y
        obligatorio entre el Usuario y la Sociedad con relación a la privacidad.
        Asimismo, la utilización del Servicio expresa la aceptación plena,
        voluntaria, informada y sin reservas del Usuario de los Términos y
        Condiciones de Utilización del Servicio (en adelante, los “
        <b>Términos y Condiciones</b>”) publicados por la Sociedad en el Sitio
        y/o la Aplicación (
        <a href="www.yopitpago.com/terminosycondiciones">
          www.yopitpago.com/terminosycondiciones
        </a>
        ), que se complementan con la presente Política de Privacidad. La
        conformidad y aceptación del usuario de la Política de Privacidad y los
        Términos y Condiciones constituye un requisito esencial para poder
        contratar y/o tener cualquier tipo de relación con la Sociedad.
      </p>

      <p>
        La Sociedad podrá modificar la Política de Privacidad en cualquier
        momento. Las nuevas versiones de la Política de Privacidad serán
        notificadas mediante publicación de dicha nueva versión en el Sitio y/o
        en la Aplicación y notificada por las vías de contacto que el Usuario
        declare en su caso.
      </p>

      <p>
        El Usuario acepta que será dado por notificado de cualquier modificación
        a la Política de Privacidad una vez que la Sociedad hubiera publicado
        las mismas en el Sitio y/o en la Aplicación, y que la continuación del
        Usuario en el uso del Servicio una vez publicada dicha nueva versión se
        considerará como aceptación de dichas modificaciones a la Política de
        Privacidad. En consecuencia, el Usuario acepta chequear el Sitio y/o la
        Aplicación periódicamente para mantenerse informado de dichas polítcas.
      </p>

      <p>
        La Sociedad recolecta información de sus Usuarios en el marco de la
        prestación del Servicio, bajo la premisa que dicha recolección y
        protección de los datos personales de los Usuarios constituye una
        oportunidad para generar valor para sus Usuarios. Por ello, para la
        Sociedad la privacidad de los datos personales es muy importante y
        realiza sus mejores esfuerzos en protegerla.
      </p>

      <p>
        Si tiene dudas con respecto a la Política de Privacidad, por favor
        escribanos a info@yopitpago.com.
      </p>

      <h2 class="subtitle">
        2. <u>DE LOS USUARIOS:</u>
      </h2>

      <p>
        2.1. En razón del medio tecnológico utilizado por los Usuarios para su
        registro, la Sociedad realizará una distinción entre dos clases de
        Usuarios: (i) Personas Físicas que utilizan la Aplicación (en adelante,
        “<b>Usuario Físico</b>”); (ii) Personas Jurídicas que utilizan el Sitio
        (en adelante, el “<b>Comercio</b>”)
      </p>

      <p>
        2.2. Los Usuarios, contarán con modalidades de Servicios distintos, sin
        embargo complementarios, destinados a cumplir con aquellas necesidades
        que cada una de las clases de Usuario obtenga.
      </p>

      <h2 class="subtitle">
        3. <u>RECOLECCIÓN DE INFORMACIÓN DE LOS USUARIOS FINALES:</u>
      </h2>

      <p>
        3.1. Los Usuarios aceptan que Sociedad pueda recolectar información
        acerca de sus Usuarios utilizando cookies, tags y otros métodos (tales
        como la información proporcionada por los Usuarios al efectuar una
        compra).
      </p>

      <p>
        3.2. Asimismo, la Sociedad puede recopilar y/o almacenar el número o
        dirección de IP (Internet Protocol) de los Usuarios a los fines de
        mejorar la calidad del Servicio que brinda a través de su Sitio y/o
        Aplicación. El almacenamiento por parte de la Sociedad del número de IP
        de los dispositivos de los Usuarios permite identificarlos al momento de
        su ingreso a sus cuentas individuales. Asimismo facilita el diagnóstico
        de los eventuales problemas de conexión que puedan llegar a existir
        entre el Sitio y Aplicación de la Sociedad y los Usuarios, mejorando la
        calidad de los Servicios.
      </p>

      <p>
        El número o dirección de IP (Internet Protocol) es una identificación
        numérica que distingue a un dispositivo electrónico informático (sea
        este una computadora, un teléfono personal con acceso a redes, una
        tableta, una PDA, etc.) que se conecta a una red informática (por
        ejemplo Internet).
      </p>

      <p>
        La Sociedad podrá solicitar a los Usuarios y almacenar sus datos
        personales y de facturación. En el supuesto de Usuarios Físicos, la
        Sociedad podrá solicitar y recolectar: (i) nombre y apellido; (ii)
        teléfono celular; (iii) DNI; (iv) correo electrónico; (v) dirección;
        (vi) código postal; (vii) ciudad; (viii) región; (ix) país de
        residencia; (x) CBU; (xi) foto de perfil; (xii) apodo o seudónimo para
        operar en el Sitio y/o Aplicación. Por otra parte, respecto del
        Comercio, la Sociedad podrá solicitar y recolectar: (i) Razón Social;
        (ii) constancia de CUIT; (iii) constancia de Ingresos Brutos; (iv)
        correo electrónico; (v) CBU; vi) apodo o seudónimo para operar en el
        Sitio y/o Aplicación (en adelante, los “<b>Datos</b>”). La recolección
        de información permite ofrecer a los Usuarios un Servicio personalizado,
        que se adecue a sus necesidades para brindar la mejor experiencia
        posible con la Sociedad.
      </p>

      <p>
        3.3. La Sociedad podrá almacenar o recopilar la información y datos de
        los medios de pago de los Usuarios como cuentas bancarias, información
        de carácter fiscal, números y nombres de identificación bancaria y
        tributaria, números de tarjeta de crédito y/o débito. La información
        relacionada con los servicios de pago podrá ser almacenada en los
        servidores de la Sociedad, pudiendo la Sociedad tener acceso a la misma.
      </p>

      <h2 class="subtitle">
        4. <u>USO DE LA INFORMACIÓN DE LOS USUARIOS RECOLECTADA:</u>
      </h2>

      <p>
        4.1. La Sociedad podrá recolectar información de los Usuarios utilizando
        cookies y/o tags y/o cualquier otro método de detección de información
        automatizada provisto por las herramientas que ofrecen la Aplicación y/o
        el Sitio. La información que recopile la Sociedad podrá incluir el
        comportamiento de navegación, dirección IP, logs, y otros tipos de
        información. Sin embargo, la Sociedad no recolectará información
        personal identificable de manera directa de ningún Usuario usando
        cookies o tags o cualquier otro método de detección de información
        automatizada provisto por las herramientas que ofrecen la Aplicación y/o
        el Sitio.
      </p>

      <p>
        4.2. La Sociedad utilizará la información provista por los Usuarios y la
        recolectada por Sociedad con el fin de proveer el Servicio y sus mejoras
        a los Usuarios, intentando ajustarse a sus necesidades.
      </p>

      <p>
        4.3. La Sociedad utilizará la información provista por los Usuarios y la
        recolectada por la Sociedad con el fin de analizarla y poder brindar a
        los Usuarios, datos relevantes para poder mejorar el servicio de pago.
        Bajo esa finalidad, la Sociedad, podrá enviar a sus Usuarios,
        notificaciones, noticias y novedades de su interés, además de aquellas
        que revistan el carácter de notificaciones de índole institucional o
        legal.
      </p>

      <p>
        4.4. La Sociedad utilizará la información provista por los Usuarios y la
        recolectada por la Sociedad para analizar las conductas y
        comportamientos de los Usuarios en carácter de tales, en su Sitio, como
        sus ubicaciones geográficas, sus sesiones de uso, permanencia en el
        Sitio, frecuencia de uso, a los efectos de intentar mejorar su Servicio
        e intentar proveerlos de mejores soluciones a sus necesidades.
      </p>

      <p>
        4.5. La Sociedad podrá compartir la información con otras empresas de
        servicios o sitios de internet o similares a los fines de mejorar la
        calidad del Servicio de la Sociedad. Generalmente dichas empresas o
        sitios de internet poseen sus propias políticas de privacidad de datos a
        los fines de su protección. De todas maneras la Sociedad empeñará sus
        mejores esfuerzos en que la privacidad de la información compartida sea
        protegida de la mejor manera posible. En los casos que corresponda la
        Sociedad intentará firmar acuerdos expresos en materia de protección de
        datos y de privacidad de la información. Sin perjuicio de ello, la
        Sociedad no será responsable por los daños provocados por tales empresas
        y/o sitios de internet en cuanto a su deber de protección,
        confidencialidad y privacidad de los datos que ellas manejan.
      </p>

      <p>
        4.6. La Sociedad utilizará la información provista por los Usuarios y la
        recolectada por la Sociedad en caso de ser solicitada por tribunales, u
        organismos estatales nacionales o internacionales que así lo requieran y
        lo soliciten de conformidad a la normativa aplicable.
      </p>

      <p>
        4.7. La Sociedad no estará obligada a retener la información durante
        ningún plazo establecido y dispondrá la eliminación de la misma cuando
        lo juzgue conveniente.
      </p>

      <h2 class="subtitle">
        5. <u>EL SITIO:</u>
      </h2>

      <p>
        5.1. La Sociedad no venderá, alquilará ni negociará ningún Dato a ningún
        tercero para fines comerciales. Cualquier persona que hubiera provisto
        información de contacto personal a través del Sitio y/o Aplicación de la
        Sociedad, podrá enviar un correo electrónico a info@yopitpago.com a fin
        de actualizar, borrar y/o corregir su información personal de contacto.
        La Sociedad responderá dicho requerimiento dentro de los 30 (treinta)
        días siguientes a la recepción del mismo vía correo electrónico.
      </p>

      <p>
        5.2. El Sitio y la Aplicación podrán utilizar cookies para registrar
        patrones de Usuarios. En caso que un Usuario de la Aplicación o Sitio no
        desee aceptar estas cookies, podrá configurar su navegador para que le
        otorgue la opción de aceptar cada cookie y rechazar las que no desee. La
        Aplicación o el Sitio también podrán utilizar herramientas de análisis
        de los cookies, log files y tags.
      </p>

      <p>
        5.3. El Sitio y/o Aplicación podrán contener enlaces a otros sitios de
        internet que no sean propiedad de la Sociedad. En consecuencia, la
        Sociedad no será responsable por el actuar de dichos sitios de internet,
        a los cuales no se aplicará la presente Política de Privacidad.
        Recomendamos examinar la política de privacidad detallada en aquellos
        sitios de internet para entender los procedimientos de recolección de
        información que utilizan y como protegen sus datos personales.
      </p>

      <p>
        5.4. Las disposiciones de la Política de Privacidad se aplicarán a todos
        los Usuarios, hayan estos ingresado sus Datos o no.
      </p>

      <h2 class="subtitle">
        6. <u>MENORES DE EDAD:</u>
      </h2>

      <p>
        6.1. Si bien el Sitio y/o la Aplicación y/o Servicio no están dirigidos
        a menores de edad, en caso en que algún menor tenga acceso a los mismos,
        su uso deberá ser supervisado por los padres, madres, tutores o
        responsables legales. El Sitio y/o la Aplicación y Servicio están
        permitidos sólo para quienes tengan edad legal para contratar y no se
        encuentren inhibidos legalmente o de algún modo vedados de ejercer actos
        jurídicos, derechos y/u obligaciones. Habida cuenta de ello, los menores
        de 18 años no tienen permitido el ingreso al Sitio y/o la Aplicación y/o
        Servicio, así como tampoco suministrar ningún Dato ni ningún otro tipo
        de información.
      </p>

      <p>
        6.2. Asimismo, toda vez que los menores de edad pueden no alcanzar a
        comprender debidamente la Política de Privacidad y sus implicancias, ni
        decidir válidamente sobre las opciones disponibles a través de sus
        Servicios, por lo que la Sociedad insta a todos los padres o
        representantes, tutores o adultos bajo cuya supervisión se encuentren
        los menores que accedan al Servicio, a participar activa y
        cuidadosamente en las actividades que el menor realice en internet o
        través del Sitio o de la Aplicación, al Servicio on-line que utilicen
        dichos menores, a la información a la que estos accedan, ya sea cuando
        dichos menores visiten el Sitio y/o Aplicación o cualquier otro sitio de
        terceros, a enseñarles y a guiarlos en cómo proteger su propia
        información personal mientras estén navegando en internet.
      </p>

      <h2 class="subtitle">
        7. <u>CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACIÓN:</u>
      </h2>

      <p>
        7.1. La Sociedad ha adoptado medidas de seguridad razonables para
        proteger la información de los Usuarios e impedir el acceso no
        autorizado a sus datos o cualquier modificación, divulgación o
        destrucción no autorizada de los mismos. La información recolectada por
        la Sociedad, será mantenida de manera estrictamente confidencial. El
        acceso a los datos personales está restringido a aquellos empleados,
        contratistas, Operadores, y representantes de la Sociedad que necesitan
        conocer tales datos para desempeñar sus funciones y desarrollar o
        mejorar nuestro Servicio. La Sociedad exige a sus proveedores los mismos
        estándares de confidencialidad. La Sociedad no permite el acceso a esta
        información a terceros ajenos a ella, a excepción de un pedido expreso
        del Usuario.
      </p>

      <p>
        7.2. Sin perjuicio de lo expuesto, considerando que internet es un
        sistema abierto, de acceso público, la Sociedad no puede garantizar que
        terceros no autorizados no puedan eventualmente superar las medidas de
        seguridad y utilizar la información de los Usuarios en forma indebida.
      </p>

      <p>
        7.3.Los Usuarios serán responsables de todos los actos que tengan lugar
        mediante el uso de su apodo y clave de acceso al Sitio y/o la
        Aplicación. Si por cualquier razón el Usuario tenga motivos para creer
        que alguien puede conocer su clave de acceso, el Usuario deberá
        modificarla ingresando desde el menú de navegación del Sitio y/o la
        Aplicación.
      </p>

      <h2 class="subtitle">
        8. <u>CAMBIOS EN LA ESTRUCTURA CORPORATIVA:</u>
      </h2>

      <p>
        8.1. La Sociedad se reserva el derecho de transferir la información
        recolectada en caso de venta o fusión de la Sociedad, o de una
        adquisición de los activos principales de la Sociedad, o cualquier otra
        clase de transferencia de la Sociedad a otra entidad y/o cambio de sus
        accionistas actuales. En dicho supuesto, la Sociedad deberá adoptar las
        medidas razonables a efectos de asegurar que dicha información sea
        utilizada de una manera consistente con la Política de Privacidad.
      </p>

      <h2 class="subtitle">
        9. <u>DERECHOS DE LOS USUARIOS SOBRE LA INFORMACIÓN:</u>
      </h2>

      <p>
        9.1. La mayoría de los navegadores están configurados para aceptar
        cookies, pero los Usuarios podrán reconfigurar su navegador de internet
        para rechazar todas las cookies o para que el sistema le indique en qué
        momento se envía una. Sin embargo, si las cookies están inhabilitadas,
        es posible que algunas características y servicios del Sitio y/o la
        Aplicación no funcionen de manera adecuada.
      </p>

      <p>
        9.2.La Sociedad intentará, por todos los medios a su alcance, facilitar
        a los Usuarios sobre los cuales haya recopilado o almacenado información
        personal, el acceso a sus Datos (“<b>Derecho de Acceso</b>”), así como
        la rectificación, modificación o actualización de los mismos (“
        <b>Derecho de Rectificación</b>”), o incluso la cancelación de dichos
        datos personales (“<b>Derecho de Remoción</b>”), a menos que la Sociedad
        pueda denegar dichas solicitudes (en adelante, las “<b>Solicitudes</b>
        ”), en caso que se encuentre obligada o tenga derecho a conservar dichos
        Datos de acuerdo a la legislación aplicable.
      </p>

      <ol class="ol-alphabetical-point">
        <li>
          A dichos efectos, el Usuario deberá enviar su Solicitud mediante el
          envío de un correo electrónico con el asunto “
          <b>Acceso a Datos Personales</b>” a info@yopitpago.com. La Sociedad
          podrá requerir a dicho Usuario que se identifique, lo que podrá ser
          verificado por la Sociedad, así como que precise los Datos a los
          cuales se desea acceder, rectificar o remover.
        </li>

        <li>
          La Sociedad podrá rechazar la tramitación de Solicitudes que sean
          irrazonablemente repetitivas o sistemáticas, que requieran un esfuerzo
          técnico desproporcionado, que pongan en peligro la privacidad de los
          demás Usuarios, o que se consideren poco prácticas, o para las que no
          sea necesario acceder a los Datos.
        </li>

        <li>
          El servicio de acceso, rectificación y remoción de Datos será prestado
          por Sociedad en forma gratuita, excepto en caso que requiriera un
          esfuerzo desproporcionado o irrazonable, en cuyo caso podrá cobrarse
          un cargo de administración.
        </li>
      </ol>

      <p>
        EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL
        DERECHO DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO
        INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL
        EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO 14, INCISO 3 DE LA LEY Nº
        25.326 DE LA REPUBLICA ARGENTINA. LA AUTORIDAD DE APLICACIÓN (EN
        ARGENTINA, LA DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES,
        ÓRGANO DE CONTROL DE LA LEY Nº 25.326), TIENE LA ATRIBUCIÓN DE ATENDER
        LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON RELACIÓN AL
        INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE DATOS PERSONALES.
      </p>

      <p>
        Datos de Contacto de la Dirección Nacional de Protección de Datos
        Personales: <br />
        Sarmiento 1118, 5º piso (C1041AAX) <br />
        Tel. 4383-8510/12/13/15 <br />
        http://www.jus.gob.ar/datos-personales.aspx/ <br />
        infodnpdp@jus.gov.ar
      </p>

      <h2 class="subtitle">
        10. <u>EXCEPCIONES:</u>
      </h2>

      <p>
        10.1. No obstante cualquier otra provisión en contrario en la Política
        de Privacidad, la Sociedad podrá divulgar cierta información personal de
        los Usuarios, cuando crea de buena fe que esa divulgación resulte
        razonablemente necesaria para:
      </p>

      <ol class="ol-alphabetical-point">
        <li>evitar una responsabilidad legal;</li>

        <li>
          cumplir una exigencia legal, tal como una orden de allanamiento, una
          citación o una orden judicial;
        </li>

        <li>
          cumplir un requerimiento de una autoridad gubernamental o reguladora;
          y/o
        </li>

        <li>
          proteger los derechos, propiedad o seguridad de la Sociedad, de los
          Usuarios, o de un tercero.
        </li>
      </ol>

      <h2 class="subtitle">
        11. <u>SERVICIOS DE TERCEROS:</u>
      </h2>

      <p>
        11.1. Sociedad podrá utilizar y utiliza servicios de terceros, de los
        que no resulta responsable por la información que recolectan ni la forma
        en que protegen su información personal. Para ello, cada uno de estos
        servicios de terceros cuentan con sus propias políticas de privacidad y
        proveen, en los casos que se encuentre disponible, un método con el que
        Usuario podrá ejercer sus derechos de acceso, rectificación y remoción.
      </p>

      <h2 class="subtitle">
        12. <u>SERVICIOS DE TERCEROS:</u>
      </h2>

      <p>
        12.1. La Sociedad podrá modificar la presente Política de Privacidad en
        cualquier momento, en caso que lo considere oportuno. En caso que las
        modificaciones sean sustanciales con relación al tratamiento de los
        Datos recolectados en virtud de la utilización de los Servicios, las
        mismas serán notificadas mediante la publicación de un aviso destacado
        en el Sitio y/o Aplicación y con el envío de un correo electrónico a la
        dirección declarada por el Usuario.
      </p>

      <h2 class="subtitle">
        13. <u>CONTACTO:</u>
      </h2>

      <p>
        13.1.En caso que el Usuario tenga alguna duda acerca de la Política de
        Privacidad, o sobre la aplicación de la misma, deberá ponerse en
        contacto con la Sociedad, en cualquier momento, vía correo electrónico a{' '}
        <a href="mailto:info@yopitpago.com">info@yopitpago.com</a>.
      </p>

      <h2 class="subtitle">
        14. <u>LEY APLICABLE Y JURISDICCIÓN:</u>
      </h2>

      <p>
        Esta Política de Privacidad se regirá por las leyes de la República
        Argentina. Ante cualquier controversia o divergencia relacionada con la
        interpretación, validez, o cumplimiento de la Política de Privacidad, el
        Usuario y la Sociedad declaran que se someterán a la jurisdicción
        exclusiva de los Tribunales Nacionales Ordinarios con asiento en la
        Ciudad Autónoma de Buenos Aires, renunciando expresamente a cualquier
        otro fuero y/o jurisdicción que pudiera corresponderles.
      </p>

      <p className="footer-text">Última actualización 11/05/2023 </p>
    </div>
  );
};

export default PoliticaDePrivacidad;
