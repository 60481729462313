const Contacto = (props) => {
    return (
        <section id="contacto">
            <div className="stripe">
                <div className="container">
                    <div className="row">
                        <article className="col-sm-1"></article>
                        <article className="col-sm-3">
                            <ul className="sidebarMenu">
                                <li className="fsmini"><strong>Yopit Pago</strong></li>
                                <li className="fsmini"><a href="/terminosycondiciones" target="_blank">Términos y condiciones</a></li>
                                <li className="fsmini"><a href="/politicadeprivacidad" target="_blank">Política de privacidad</a></li>
                                <li className="fsmini"><a href="/tarifas" target="_blank">Tarifas</a></li>
                                {/* <li className="fsmini"><a href="#a">Respaldo en tu venta</a></li>
                                <li className="fsmini"><a href="#a">Preguntas frecuentes</a></li>
                                <li className="fsmini"><a href="#a">Quiénes somos</a></li>                                 */}
                            </ul>
                        </article>
                        <article className="col-sm-3">
                            <div className="avalado">
                                    <img className="iconosAvalado" src="./assets/pci-icon.svg" alt="pci-icon"  width={100}/>
                                    <img className="iconosAvalado" src="./assets/bcra-icon.svg" alt="bcra-icon"  width={100}/>
                            </div>
                        </article>
                        <article className="col-sm-4">
                            <p className="fslegal">BenkoPay SA. y la Aplicación YOPIT Pago no son un banco ni una entidad financiera de la Ley Nro. 21.526. BenkoPay S.A. se limita a ofrecer servicios de pago y no se encuentra autorizada a operar como entidad financiera por el Banco Central de la República Argentina. Los fondos depositados en las cuentas de la Aplicación YOPIT Pago no constituyen depósitos en una entidad financiera, ni cuentan con ninguna de las garantías que tales depósitos puedan gozar de acuerdo con la legislación y reglamentación aplicables en materia de depósitos en entidades financieras.</p>
                            <p className="fsmini">
                                <img src="./assets/headphones.svg" alt="contacto" width="30px" height="auto" /> 0800 222 3442
                            </p>
                            <div className="descargas">
                                <a href="https://play.google.com/store/apps/details?id=com.mobile.yopit" target="_blank" title="disponible en PlayStore">
                                    <img src="./assets/playstore2.svg" alt="playstore" />
                                </a>
                                <a href="https://apps.apple.com/ar/app/yopit/id1581669259" target="_blank" title="disponible en AppStore">
                                    <img src="./assets/appstore2.svg" alt="playstore" />
                                </a>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Contacto;