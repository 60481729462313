import React from 'react';

import '../shared/styles/styles.css';

const TerminosYCondiciones = () => {
  return (
    <div className="doc_container">
      <h1 className="title">TÉRMINOS Y CONDICIONES</h1>

      <p>
        Los presentes términos y condiciones de servicio regulan la relación
        contractual entre los –Clientes y Comercios (en adelante “
        <b>Usuarios</b>” o el “<b>Usuario</b>”) de la aplicación “Yopit Pago” y
        del Sitio “<a href="www.yopitpago.com">www.yopitpago.com</a>” (en
        adelante, la “Aplicación” y el “Sitio” respectivamente, y en forma
        conjunta la ”<b>Plataforma</b>”) y los Servicios allí ofrecidos, con{' '}
        <b>BENKOPAY S.A</b>, CUIT 30-71583063-5; (en adelante, “LA SOCIEDAD” y
        junto con los Usuarios, las “<b>Partes</b>”). Los Usuarios se
        encontrarán sujetos a los Términos y Condiciones Generales respectivos,
        junto con todas las demás políticas y principios que son aplicables a LA
        SOCIEDAD y que son incorporados al presente por referencia.
      </p>

      <p>
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES,
        LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ
        ABSTENERSE DE UTILIZAR LA APLICACIÓN Y/O EL SITIO Y/O LOS SERVICIOS.
      </p>

      <p>
        El Usuario, previo a su registración como Usuario, debe leer, entender y
        aceptar todas las condiciones establecidas en los Términos y Condiciones
        Generales y en las Políticas de Privacidad, optando de forma expresa por
        recibir los mismos y toda otra información por medios digitales.
      </p>

      <p>
        LA SOCIEDAD provee la Plataforma con el fin de que aquella sea utilizada
        como un servicio de procesamiento de pagos, a fin de pagar o percibir
        determinados montos por cuenta y orden del Cliente (en adelante, los “
        <b>Clientes</b>”) a través de la Plataforma, constituyendo un monedero
        electrónico universal que permite a los Clientes abonar compras a través
        de la tecnología de código QR, en aquellos comercios adheridos a la
        Plataforma (en adelante, los “<b>Comercios</b>”), para facilitar el
        cobro y acreditación de los productos y/o servicios ofrecidos por
        aquellos a través de la tecnología de código QR, logrando así que los
        Comercios tengan la posibilidad de vincular y simplificar el pago de
        compras a sus propios clientes que sean Clientes de BENKOPAY.
      </p>

      <br />
      <br />

      <h2 class="subtitle">
        CLAUSULA PRIMERA:<u>El Registro</u>
      </h2>

      <p>
        1.1. Para el acceso a los servicios prestados por LA SOCIEDAD, el
        Usuario deberá completar todos los campos solicitados al momento del
        registro con datos válidos. Para registrarse el Usuario deberá tener
        como mínimo 18 años de edad cumplidos y contar con capacidad legal para
        celebrar contratos jurídicamente vinculantes conforme a la legislación
        vigente de la República Argentina. Quien aspire a convertirse en Usuario
        deberá verificar que la información que pone a disposición de LA
        SOCIEDAD sea exacta, precisa y verdadera (en adelante, los "
        <b>Datos Personales</b>"). Asimismo el Usuario asumirá el compromiso de
        actualizar los Datos Personales cada vez que los mismos sufran
        modificaciones. LA SOCIEDAD podrá utilizar diversos medios para
        identificar a los Usuarios, pero LA SOCIEDAD no se responsabiliza por la
        certeza de los Datos Personales que sus Usuarios pongan a su
        disposición.
      </p>

      <p>
        LA SOCIEDAD se reserva el derecho de solicitar comprobantes y/o
        información adicional a efectos de corroborar la información entregada
        por un Usuario en materia de Datos Personales, origen de fondos, así
        como de suspender temporal o definitivamente a aquellos Usuarios cuyos
        datos no hayan podido ser confirmados.
      </p>

      <p>
        LA SOCIEDAD es Sujeto Obligado ante la Unidad de Información Financiera
        ("UIF"), conforme a la Resolución 76/2019. En función a ello, y en
        cumplimiento de las regulaciones vigentes en materia de Prevención del
        Lavado de Dinero y Financiamiento del Terrorismo (“PLD/FT”), se
        encuentra obligado a recabar cierta información y/o documentación de los
        Usuarios, la cual deberá mantenerse actualizada, para dar cumplimiento a
        procesos de identificación, verificación, conocimiento y monitoreo de
        los mismos y su operatoria, que podrá variar, de acuerdo con la
        actividad del Usuario dentro de la Plataforma o de los Servicios que el
        Usuario utilice. Atento a ello, LA SOCIEDAD se reserva el derecho de
        rechazar una solicitud de registro o de cancelar o suspender, temporal o
        definitivamente una Cuenta (conforme es definida a continuación), (i) en
        caso de detectar incongruencias o inconsistencias en la información
        provista por un Usuario, o actividades inusuales o sospechosas del
        Usuario en materia de PLD/FT o de fraude; o (ii) en caso que el Usuario
        no proporcione o no actualice en tiempo y forma la información y/o
        documentación requerida por LA SOCIEDAD, sin que tal decisión genere
        para el Usuario derechos de indemnización o resarcimiento. En este
        sentido, al aceptar los presentes Términos y Condiciones el Usuario
        declara que todos los fondos con los que opere en la Plataforma
        provienen de fuentes lícitas, y no constituyen incumplimiento a las
        leyes vigentes en materia de Lavado de Dinero y Financiación del
        Terrorismo.
      </p>

      <p>
        1.2. A los efectos de adquirir la condición de Usuario de LA SOCIEDAD,
        el Usuario deberá completar el formulario de registro, aceptar la
        Política de Privacidad, y los presentes Términos y Condiciones.
      </p>

      <p>
        1. 3. Una vez efectuado el Registro, LA SOCIEDAD otorgará al Usuario una
        cuenta personal para acceder con la contraseña que elija (en adelante la
        “<b>Cuenta</b>”). El Usuario accederá a su Cuenta mediante el ingreso de
        su número de celular o correo electrónico y contraseña de seguridad
        personal elegida. La Cuenta es personal, única e intransferible. El
        Usuario será el único responsable del uso y de la seguridad de su
        cuenta, así como de la gestión de la configuración de seguridad que se
        pone a su disposición, y de lo que suceda en la misma hasta que se
        produzca, por cualquiera de las causales posteriormente expuestas, el
        cierre de la cuenta. Al respecto, el Usuario asume las siguientes
        obligaciones: i) mantener el control exclusivo y seguro de su contraseña
        y demás información necesaria para ingresar, como, por ejemplo, no
        divulgar a terceros claves o preguntas de seguridad, ii) No permitir que
        otras personas utilicen su cuenta así como tampoco utilizar la cuenta de
        otro Usuario, iii) dar aviso en forma inmediata y por medio idóneo y
        fehaciente a LA SOCIEDAD ante sospechas de violación de las condiciones
        de seguridad o de algún incumplimiento legal por parte de terceros, y
        cualquier uso no autorizado de su Cuenta, así como el ingreso por
        terceros no autorizados a la misma. iv) proveer de modo completo y
        preciso toda la información requerida a los fines de su identificación y
        mantenerla actualizada, v) utilizar su cuenta conforme a lo previsto en
        estos Términos y Condiciones, y demás disposiciones legales y
        contractuales aplicables. LA SOCIEDAD no será responsable de ningún tipo
        de daño o perjuicio que pudiere sufrir el Usuario por el incumplimiento
        de las obligaciones atinentes a la seguridad de su Cuenta y Contraseña
        de Seguridad.
      </p>

      <p>
        1.4. Los Datos Personales introducidos por el Usuario deberán ser
        exactos, actuales y veraces en todo momento. Los Usuarios garantizan y
        responden, en cualquier caso, de la veracidad, exactitud, vigencia y
        autenticidad de los Datos Personales puestos a disposición de LA
        SOCIEDAD. LA SOCIEDAD se reserva el derecho de solicitar algún
        comprobante y/o dato adicional a efectos de corroborar los Datos
        Personales, y de suspender temporal y/o definitivamente a aquel Usuario
        cuyos datos no hayan podido ser confirmados. LA SOCIEDAD no se
        responsabiliza por la certeza de los datos consignados en el Registro.
        Los Datos Personales que el Usuario proporcione se integrarán en una
        base de datos personales de la que es responsable LA SOCIEDAD. Para más
        información consultar la Política de Privacidad (
        <a href="http://www.yopitpago.com/politicadeprivacidad">
          http://www.yopitpago.com/politicadeprivacidad
        </a>
        ).
      </p>

      <p>
        1.5. Queda prohibido que un competidor acceda a los servicios prestados
        por LA SOCIEDAD, salvo consentimiento previo de LA SOCIEDAD. También
        queda prohibido acceder a los Servicios prestados por LA SOCIEDAD con el
        fin de monitorear su desempeño o funcionalidad, publicándolos o
        haciéndolos accesibles a cualquier competidor o tercero no regido por
        estos términos y condiciones, o para cualquier otro punto de referencia
        o propósitos competitivos.
      </p>

      <p>
        1.6. <b>Consentimiento:</b>
        <br />
        1.6.1. El/la Usuario/a brinda su consentimiento para enrolar/vincular
        la/s cuenta/s la vista / de pago que usará en la Plataforma Yopit Pago
        de Benkopay S.A. El Usuario reconoce que la Plataforma Yopit Pago es
        provista por Benkopay S.A. Asimismo, El/la Usuario/a declara y reconoce
        que todas cuentas a la vista y/o cuentas de pago enroladas son de su
        titularidad y provistas por Benkopay S.A.
      </p>

      <p>
        1.6.2. Toda instrucción de pago o de débito ordenada por el/la Usuario/a
        mediante la Plataforma Yopit Pago de Benkopay S.A. se cursará mientras
        esté vigente el consentimiento o la autorización otorgada por el/la
        Usuario/a.
      </p>

      <p>
        1.6.3. Oportunamente, el/la Usuario/a tendrá la posibilidad de
        establecer y modificar parámetros de uso de su cuenta/s a la vista / de
        pago en la Plataforma.
      </p>

      <p>
        1.6.4. Por último, el/la Usuario podrá desvincular la cuenta a la vista
        enrolada a través de Plataforma Yopit Pago de Benkopay S.A. de acuerdo a
        los procedimientos detallados en los presentes Términos y Condiciones y
        los que se establezcan a futuro.
      </p>

      <br />

      <h2 class="subtitle">
        CLAUSULA SEGUNDA<u>Condiciones Generales del Servicio - Operatoria</u>
      </h2>

      <p>
        2.1. La Aplicación de LA SOCIEDAD está dirigida exclusivamente a los
        Usuarios que previamente hayan registrado su Cuenta de acuerdo a lo
        establecido en la cláusula primera.
      </p>

      <p>
        2.2. El servicio a los Clientes (en adelante, el “<b>Servicio</b>”)
        consiste en simplificar el manejo del dinero en efectivo, facilitando a
        los Clientes el pago de compras en los Comercios, a través de la
        tecnología de código QR (en adelante, el “<b>Código</b>”). Para ello, LA
        SOCIEDAD ofrece dentro de la Aplicación una cuenta virtual personal (en
        adelante, la “<b>Cuenta Virtual</b>”), sobre la cual el Usuario tendrá
        la posibilidad de abonar o transferir dinero a terceros. La Cuenta
        Virtual es por ende la cuenta recaudadora en la que LA SOCIEDAD
        acreditará al Cliente los fondos recibidos mediante los Servicios de
        procesamiento de pagos.
      </p>

      <p>
        El servicio a los Comercios (en adelante “<b>Servicio</b>”) consiste en
        simplificar el manejo del dinero en efectivo, facilitando a los
        Comercios el cobro de compras de sus productos y/o servicios, realizadas
        por los Clientes, a través de la tecnología de código QR (en adelante,
        el “Código”). Para ello, LA SOCIEDAD ofrece al Comercio un Código
        individualizado con el fin de prestar simplicidad y rapidez de
        transacción entre el Comercio y el Cliente. Una vez efectuada la
        transacción entre el Comercio y el Cliente, el pago se acreditará en una
        cuenta bancaria exclusiva de LA SOCIEDAD, monto que será posteriormente
        transferido al Comercio. Ante ello, LA SOCIEDAD ofrecerá dentro de la
        Plataforma, un registro de los pagos realizados por los Clientes.
      </p>

      <p>
        El Servicio se instrumentará mediante la celebración de tiempo en tiempo
        entre el Usuario y LA SOCIEDAD de contratos de procesamiento de pagos
        (cada uno, una "Solicitud") mediante los cuales LA SOCIEDAD
        instrumentará determinadas operaciones de procesamiento de pagos de
        acuerdo a las instrucciones del Usuario (los “Servicios de Procesamiento
        de Pagos”), a fin de pagar o percibir determinados montos por cuenta y
        orden del Usuario a través de la Plataforma.
      </p>

      <p>
        Al completar una Solicitud, el Usuario otorgará un mandato irrevocable a
        LA SOCIEDAD para pagar o cobrar por su cuenta y orden una determinada
        suma de dinero según se detalle en la Solicitud. El mandato irrevocable
        otorgado por el Usuario implica una autorización a favor de LA SOCIEDAD
        para disponer en su nombre de ciertos fondos de su Cuenta Virtual de
        acuerdo con sus instrucciones, o bien utilizar otros medios de pagos
        disponibles para realizar el pago correspondiente. En caso de realizar
        una instrucción de pago, LA SOCIEDAD, utilizará los fondos disponibles
        en la Cuenta Virtual, o utilizará otros medios de pago adheridos para
        cumplir con la Solicitud de Pago. Los Usuarios titulares de una Cuenta
        Virtual podrán realizar transferencias a otros Usuarios titulares de una
        Cuenta Virtual.LA SOCIEDAD no estará obligada a efectuar las operaciones
        solicitadas por el Usuario cuando la Cuenta Virtual no cuente con los
        fondos suficientes para ello.
      </p>

      <p>
        LA SOCIEDAD se reserva el derecho de no procesar aquellas Solicitudes de
        Procesamiento de Pago que estén incompletas o en las cuales haya
        discrepancias entre los datos provistos por los Usuarios y los datos
        ingresados efectivamente a la Plataforma o porque LA SOCIEDAD lo
        considere necesario, sin necesidad de justificar su decisión. El Usuario
        es el exclusivo responsable por las instrucciones de pago y sus
        consecuencias. LA SOCIEDAD no verificará la causa u obligación que
        originó la instrucción de pago, ni las demás circunstancias relativas a
        la instrucción de pago.
      </p>

      <p>
        Una vez que el Usuario posea saldo en la Cuenta Virtual de LA SOCIEDAD,
        a través de transferencia electrónica de fondos, el Cliente tendrá la
        posibilidad de realizar compras en los Comercios.
      </p>

      <p>
        Asimismo, LA SOCIEDAD le otorga a los Usuarios la posibilidad de
        realizar transferencias a terceros. Para ello, LA SOCIEDAD solicitará al
        Usuario los datos del CBU/CVU.
      </p>

      <p>
        2.3. En todos los casos el Servicio será prestado por LA SOCIEDAD al
        Usuario en forma onerosa de acuerdo a las tarifas establecidas por LA
        SOCIEDAD que se encuentran publicadas en el siguiente link{' '}
        <a href="http://www.yopitpago.com/tarifas">
          http://www.yopitpago.com/tarifas
        </a>
        .
      </p>

      <p>
        2.4. El Sistema de Procesamiento de Pagos está integrado por diversas
        herramientas conforme se detalla a continuación, algunas de las cuales
        podrán ser las siguientes:
      </p>

      <p>
        (i) Servicio [YOPIT Pago] POS: es el servicio de procesamiento de pagos
        que le permitirá al Usuario recibir pagos a través de tarjetas de
        crédito y de débito aceptadas por LA SOCIEDAD. Para poder utilizar este
        servicio deberá adquirir un POS móvil y firmar el contrato de comodato
        correspondiente. El POS móvil será utilizado para la lectura de la
        tarjeta de débito o crédito y a través de la Plataforma, se ingresarán
        los datos necesarios para realizar el pago aceptando los términos y
        condiciones del mismo. El comprobante será digital y se puede compartir
        a través de servicios de mensajería digital o imprimir en una impresora
        común.
      </p>

      <p>
        (ii)Servicio de botón de Pago de [YOPIT Pago]: Es el servicio que
        permite al Usuario recibir pagos a través de tarjetas de crédito y
        débito aceptadas por LA SOCIEDAD. Para poder utilizar este servicio, el
        Usuario deberá iniciar sesión en la Plataforma y se le dará de alta el
        servicio. Podrá realizar cobros con las tarjetas aceptadas eligiendo la
        opción de cobrar.
      </p>

      <p>
        (iii) El Servicio de Procesamiento de Pagos desde las Cuentas Virtuales:
        Es el servicio a través del cual el Usuario podrá instruir a LA SOCIEDAD
        a realizar pagos o a recibir pagos por su cuenta y orden, a través de la
        Plataforma. A partir de este servicio, el Usuario titular de la cuenta
        podrá realizar transferencias a clientes a cualquier banco (a cualquier
        CBU) o Proveedor de Servicios de Pago (a cualquier CVU). Para poder
        utilizar este servicio, en la etapa de registro u “onboarding” a la
        Plataforma, el Usuario debe validar su DNI a partir del ingreso del
        número de trámite de su DNI y se solicitará la validación de ese
        documento a través de servicios otorgados por terceros, que se
        encuentran conectados a la base de datos de RENAPER.
      </p>

      <p>
        (iv) El Servicio de Procesamiento de Pagos mediante Lectura de Código
        QR: El Servicio permite a los Usuarios compradores o vendedores recibir
        o realizar pagos mediante la lectura de Códigos QR, proporcionados por
        la Plataforma. Todos los cobros y pagos realizados por los Usuarios a
        través de la lectura del Código QR se verán detallados en la actividad
        de la Cuenta Virtual.
      </p>

      <p>
        (v) El Servicio de Pago de Servicios Públicos, Servicios de Telefonía
        celular e Impuestos: Los Usuarios podrán efectuar pagos de servicios e
        impuestos de aquellas empresas de servicios adheridas al Sistema Pronto
        Pago, administrado por Pronto Pago S.A., con tarjeta de débito, crédito
        y/o con cualquier medio de pago habilitado por Pronto Pago S.A. y por LA
        SOCIEDAD.
      </p>

      <p>
        (vi) El Servicio de Procesamiento de Pagos mediante la adhesión de
        tarjeta de crédito y/o débito. Para utilizar este servicio el Usuario
        deberá adherir sus tarjetas de crédito, débito y/o tarjetas prepagas a
        su Cuenta Virtual. Una vez adheridas dichas tarjetas, las mismas quedan
        habilitadas para operar, y el Usuario podrá utilizar dichas tarjetas
        para realizar pagos a terceros que acepten la Plataforma como medio de
        pago.
      </p>

      <p>
        (vii) El Servicio Extracash, mediante el cual un Usuario vendedor que
        utilice el Servicio Extracash puede ofrecer a sus clientes retirar
        efectivo de un comercio del Usuario vendedor. Esto puede ser realizado:
        mediante la lectura de la tarjeta de débito del Cliente en cuestión.. En
        la medida que el servicio se realice con la modalidad indicada en (c)
        será administrado por Pronto Pago S.A., y será ofrecido mediante un
        contrato entre LA SOCIEDAD y Pronto Pago S.A.
      </p>

      <p>2.5. Operatoria:</p>

      <p>
        Una vez registrado el Usuario en la Plataforma, LA SOCIEDAD procederá a
        vincular a dicho registro a una Cuenta Virtual a nombre del Usuario. Un
        Usuario podrá habilitar una Cuenta Virtual YOPIT Pago “Cliente”, o una o
        varias Cuentas Virtuales Yopit Pago “Comercio”.
      </p>

      <p>
        Las Cuentas Virtuales Comercio, podrán ser administradas por diferentes
        apoderados, que a su vez podrán generar autorizaciones para operar las
        mismas. El Servicio de cobros a través de POS únicamente será prestado a
        Usuarios que tengan Cuentas Virtuales Comercio. Asimismo los titulares
        de las Cuentas Virtuales Comercio, podrán recibir una compensación de
        parte de YOPIT Pago, por la venta de los servicios de recarga de
        celular, pago de servicios o impuestos prestados por YOPIT Pago,
        conforme los valores indicados en el siguiente link{' '}
        <a href="http://www.yopitpago.com/tarifas">
          http://www.yopitpago.com/tarifas
        </a>
        . Toda transacción que se efectúe desde la Plataforma mediante la Cuenta
        Virtual se entenderá realizada personalmente por el Usuario,
        considerándose las mismas como válidas, legítimas, auténticas y
        vinculantes, sin necesidad de realizar otro tipo de confirmación.
      </p>

      <p>
        Todas las operaciones efectuadas por el Usuario serán registradas en la
        fecha y horario en que efectivamente se cursen. En ciertos casos, la
        fecha u horario de registro de la operación podrá ser posterior al
        momento en que se haya solicitado su ejecución, dependiendo del tipo de
        operación. Asimismo, el registro de la fecha y horario de las
        operaciones de recarga y extracción de dinero pueden encontrarse sujetas
        a condiciones adicionales por parte de cada tercero prestador de
        servicios.
      </p>

      <p>
        Una vez efectuadas, las operaciones llevadas a cabo por el Usuario no
        podrán revocarse. La confirmación por parte de LA SOCIEDAD se realizará
        a través de un mensaje de confirmación para cada operación en
        particular, el cual se visualizará en la Plataforma al concluir cada
        transacción. El Usuario libera a LA SOCIEDAD de toda responsabilidad por
        las operaciones llevadas a cabo a través de su Cuenta Virtual, y
        garantiza mantener indemne a LA SOCIEDAD por cualquier reclamo, daño y/o
        perjuicio derivado directa o indirectamente de la utilización de su
        Cuenta Virtual.
      </p>

      <p>
        El Usuario podrá consultar el saldo y estado de sus Cuentas Virtuales
        desde la Plataforma mediante el ingreso de su usuario de acceso y su
        clave privada las 24 horas del día. Asimismo se le asignará a cada
        Cuenta Virtual del Usuario una Clave Virtual Uniforme (“CVU”), para la
        utilización de los servicios.
      </p>

      <p>
        El Usuario autoriza a LA SOCIEDAD a efectuar los movimientos
        correspondientes en sus Cuentas Virtuales para el pago de las
        operaciones que el Usuario solicite, siempre de acuerdo con los topes y
        restricciones fijados para una Cuenta Virtual. La SOCIEDAD no estará
        obligada a efectuar las operaciones solicitadas por el Usuario cuando la
        Cuenta Virtual no cuente con los fondos suficientes para ello y/o por
        otros motivos de seguridad u operativos.
      </p>

      <p>
        La Cuenta Virtual de cada Usuario podrá recibir fondos desde cualquier
        cuenta bancaria con CBU y/o desde cualquier cuenta de pago de
        Proveedores de Servicios de Pago, con Clave Virtual Uniforme [CVU].
        Dichas cuentas podrán estar o no a nombre del Cliente. En caso de que la
        Cuenta Virtual reciba fondos desde cuentas de otra titularidad LA
        SOCIEDAD podrá requerir al Cliente que provea la información necesaria
        para justificar el origen de los fondos. Asimismo se acreditarán en la
        Cuenta Virtual los fondos correspondientes a los cobros realizados a
        través de YOPIT Pago de las ventas de los Usuarios.
      </p>

      <p>
        El Usuario podrá en cualquier momento requerir la transferencia de los
        fondos desde la Cuenta Virtual a cualquier cuenta bancaria con CBU y/o
        desde cualquier cuenta de pago de Proveedores de Servicios de Pago, con
        Clave Virtual Uniforme [CVU]. Para poder efectuar transferencias, el
        Usuario debe ir a la sección “Enviar Dinero” de la Plataforma, pudiendo
        en dicha opción definir si va a realizar una transferencia a una cuenta
        bancaria con CBU o a una cuenta de Proveedor de Servicios de Pago bajo
        un CVU. Luego deberá seleccionar el monto, el destinatario y concepto de
        la transferencia y luego se le requiere que indique su clave numérica de
        seis dígitos (“PIN”). Se le dará la opción de confirmar la
        transferencia, otorgándole un comprobante por dicha transacción.
      </p>

      <p>
        Los fondos provenientes de los cobros de las compras realizadas por los
        compradores al Usuario, serán acreditados en la Cuenta Virtual de manera
        inmediata en la medida que sean disponibilizados por las entidades
        intermediarias intervinientes, como pueden ser entidades bancarias. Los
        fondos correspondientes a las transferencias requeridas por los Usuarios
        serán acreditados en los plazos de manera inmediata, siendo de
        aplicación los plazos que tomen las entidades que procesan las
        transferencias, como ser las entidades bancarias, para poder realizar la
        acreditación de los fondos correspondientes.
      </p>

      <p>
        Los créditos y débitos correspondientes a la Cuenta Virtual se mostrarán
        inicialmente dentro del registro de actividad de la Cuenta Virtual
        incluido en la Plataforma como “saldo a acreditarse”, estableciendo la
        fecha de acreditación estimada. En la fecha de acreditación, los fondos
        correspondientes pasarán a ser considerados como “saldo disponible” de
        la Plataforma.
      </p>

      <p>
        Los fondos acreditados en la Cuenta Virtual de los Usuarios se
        encontrarán en todo momento disponibles por parte de los Usuarios. Los
        fondos disponibles o pendientes de liquidación en la Cuenta no generan
        intereses a favor de los Usuarios.
      </p>

      <p>
        El 100% de los fondos de los Usuarios correspondientes a sus Cuentas
        Virtuales se encontrará depositado en cuentas a la vista en pesos en
        entidades financieras del país.LA SOCIEDAD no será responsable en ningún
        caso por hechos que afecten la disponibilidad de los fondos y/o por la
        insolvencia de dichas entidades. Como consecuencia de ello, LA SOCIEDAD
        no será responsable por hechos o acontecimientos que restrinjan o
        imposibiliten la libre disponibilidad de los fondos del Usuario. Tampoco
        LA SOCIEDAD responderá por caso fortuito o por cualquier otra causa de
        imposibilidad de cumplimiento de los Términos y Condiciones, que no
        fueran atribuibles a su parte.
      </p>

      <p>
        Las transacciones que LA SOCIEDAD realice por cuenta propia para su
        propia operación será realizada utilizando una cuenta a la vista
        distinta a la cuenta en la que se encuentren depositados los fondos de
        los Usuarios correspondientes a las Cuentas Virtuales.
      </p>

      <p>
        LA SOCIEDAD podrá establecer límites de cantidad y montos para el
        Servicio y para operaciones de transferencias de fondos a la cuenta
        bancaria del Usuario. Actualmente el límite mínimo de las operaciones
        que no incluyen pagos de servicios o de recargas de servicios es de $1
        (pesos uno) sin existir un límite máximo, pero dichos límites podrán
        modificarse en el futuro y podrán diferir dependiendo del Servicio que
        se requiera. Estos límites no aplicarán en caso de retiro de fondos de
        la Cuenta Virtual a cuentas bancarias o en otras cuentas virtuales PSP
        de titularidad del Usuario.
      </p>

      <p>
        En caso que existan, se encuentren o se sospeche el acaecimiento el uso
        ilegal del Servicio, fraude, defraudaciones y/o cualquier otro acto que
        infrinja y/o contrariase, tanto a los presentes Términos y Condiciones
        como así también la normativa legal vigente, LA SOCIEDAD se reserva el
        irrevocable derecho de: 1) No procesar ni/o acreditar un determinado
        pago; 2) Suspender temporalmente la disponibilidad de los fondos de la
        Cuenta Virtual del Usuario; 3) Bloquear toda transferencia de fondos
        solicitada por el Usuario a su Cuenta Bancaria; 4) Suspender por tiempo
        indeterminado la Cuenta Virtual del Usuario; o 5) Disponer el cierre de
        la Cuenta Virtual del Usuario.
      </p>

      <p>
        Por razones de seguridad, los Fondos que se acrediten en la Cuenta del
        Usuario y/o que estén pendientes de liquidación (sin importar el medio
        de pago por el que se hubiera efectuado la transacción) podrán
        permanecer indeterminadamente retenidos cuando, a exclusivo criterio de
        LA SOCIEDAD, existan fuertes sospechas de ilegalidades, fraude, excesiva
        cantidad de operaciones o transacciones que puedan ser fraudulentas y/o
        excesivos pedidos de devoluciones y/o contracargos, o cualquier otro
        acto contrario a estos Términos y Condiciones y/o sospechas de violación
        de preceptos legales por los cuales LA SOCIEDAD deba responder.
      </p>

      <p>
        El Usuario será responsable por el pago de cualquier multa, tasa,
        penalidad, daño, costo, o reclamo, que sea aplicado a LA SOCIEDAD por
        parte de empresas adquirentes y/o marcas de tarjetas de crédito, como
        resultado de contracargos relacionados con las transacciones hechas por
        el Usuario.
      </p>

      <p>
        El Usuario autoriza expresamente a LA SOCIEDAD a debitar de su Cuenta
        Virtual y/o a compensar de cualquier otro ingreso futuro de fondos de su
        Cuenta Virtual y/o a reclamar al Usuario para que abone mediante el
        instrumento de pago que LA SOCIEDAD defina; toda cifra que llegue a
        adeudar derivada de la utilización del Servicio y/o de la Plataforma.
      </p>

      <p>2.6. Operaciones prohibidas:</p>

      <p>
        El Usuario entiende, acepta y declara que la Cuenta Virtual debe
        utilizarse exclusivamente para la realización de Operaciones de acuerdo
        a los presentes Términos y Condiciones y a las normas legales vigentes.
        A tal fin, el Usuario acepta y reconoce expresamente que la Cuenta
        Virtual no realizará ventas o transmisiones prohibidas por la ley o por
        estos Términos y Condiciones, tales como las descriptas a continuación,
        pero sin limitarse a:(i) juegos de azar, apuestas, etc.; (ii) tráfico de
        armas, de personas, de animales, etc.; (iii) lavado de activos y/o
        financiación del terrorismo; (iv) pornografía, prostitución o pedofilia;
        y/o (v)cualquier tipo de actividad que pueda ser considerada fraudulenta
        o ilegal o sospechosa de serlo. El Usuario no utilizará la Cuenta
        Virtual y sus servicios para transmitir material que constituya un
        delito o bien que pueda dar lugar, directa o indirectamente a
        responsabilidades civiles o penales o que infrinjan estos Términos y
        Condiciones. Si la SOCIEDAD considera que hay una sospecha o indicio de
        la utilización de la Cuenta Virtual y sus servicios para alguna
        actividad prohibida por la ley o estos Términos y Condiciones, podrá́
        solicitar al Usuario información delas operaciones realizadas, rechazar,
        cancelar o suspender cualquier transferencia o actividad cursada o por
        cursarse con la misma y/o bloquear temporalmente el acceso y uso de la
        Cuenta Virtual y/o la utilización o disponibilidad de funcionalidades
        y/o cancelar definitivamente la Cuenta Virtual, no permitiendo al
        Usuario abrir otra Cuenta por el tiempo que determine, incluso de forma
        permanente. En tal caso, el Usuario responderá́ por los daños y
        perjuicios que pueda haber ocasionado a la Plataforma, sus controlantes,
        controladas, filiales o subsidiarias, funcionarios, empleados,
        directores, agentes, y/o empleados, a cuyos efectos la SOCIEDAD se
        reserva el derecho de iniciar las acciones judiciales o extrajudiciales
        que estime pertinentes. El Usuario será́ exclusiva e ilimitadamente
        responsable por los perjuicios que su conducta pueda causar a La
        SOCIEDAD o a los restantes Usuarios y/o a terceros.
      </p>

      <p>2.7. Restitución de Fondos a Usuarios:</p>

      <p>
        El Usuario podrá en cualquier momento requerir la transferencia de los
        fondos desde la Cuenta Virtual a cualquier cuenta bancaria con CBU y/o
        desde cualquier cuenta de pago de Proveedores de Servicios de Pago, con
        Clave Virtual Uniforme [CVU]. Para poder realizar transferencias
        salientes, el Usuario deberá seleccionar la opción de “Enviar Dinero”
        disponible en la Plataforma, seleccionar monto y destinatario. Se le
        dará la opción de confirmar la transferencia, otorgándole un comprobante
        por dicha transacción.
      </p>

      <p>
        Asimismo, en caso de que LA SOCIEDAD cese su actividad, aplicará el
        siguiente procedimiento: <br /> 0) Con previo aviso a los Usuarios se
        dejará de cobrar por tarjeta de crédito y débito. De esta forma LA
        SOCIEDAD se asegurará que al menos 30 días antes de desconectar el
        servicio todo el dinero de los Usuarios estará como “saldo disponible”
        en las Cuentas Virtuales.
        <br /> 1) Con una anticipación de al menos 30 días se notificará a los
        Usuarios por correo electrónico y/o al celular, que LA SOCIEDAD
        desconectará, solicitando a los Usuarios que procedan a ordenar el
        retiro de los fondos.
        <br /> 2) 15 días antes de desconectar el servicio se enviará un
        recordatorio a todos los Usuarios con saldo superior a $0 en su Cuenta
        Virtual.
        <br /> 3) 7 días antes de desconectar el servicio se enviará un
        recordatorio a todos los Usuarios saldo superior a $0 en su Cuenta
        Virtual.
        <br /> 4) 24hs hábiles después de desconectar el servicio se le enviará
        a todos los Usuarios con saldo superior a $0 un mensaje con la
        posibilidad de retirar los fondos a una CBU/CVU o donarlo a una ONG
        elegida por LA SOCIEDAD con una fecha límite de 30 días adicionales para
        dar una respuesta.
        <br /> 5) 15 días después de desconectar el servicio se le enviará a
        todos los usuarios con saldo superior a $0 un mensaje con la posibilidad
        de retirar los fondos a una CBU/CVU o donarlo a una ONG elegida por LA
        SOCIEDAD con una fecha límite de 15 días para dar una respuesta.
        <br /> 6) 31 días después de la desconexión del servicio, en la medida
        que los Usuarios no hubieran contestado los recordatorios, se realizará
        una donación por el total de los importes "no retirados" a una ONG
        elegida por LA SOCIEDAD.
      </p>

      <p>
        Los fondos correspondientes a las transferencias requeridas por los
        Usuarios o correspondientes a la restitución de fondos en caso de cese
        de actividad serán acreditados en forma inmediata, siendo de aplicación
        los plazos que requieran quienes intervienen en el medio de pago elegido
        (tal como entidades bancarias) para procesar el mismo.
      </p>

      <h2 class="subtitle">
        CLAUSULA TERCERA:<u>Condiciones de Pago.</u>
      </h2>

      <p>
        3.1 Por la utilización del Servicio de Procesamiento de Pago el Usuario
        acepta pagar a LA SOCIEDAD una tarifa (la "Tarifa”) cada vez que reciba
        o egrese dinero en su Cuenta Virtual. La Tarifa aplicable será la
        prevista en el siguiente link{' '}
        <a href="http://www.yopitpago.com/tarifa">
          http://www.yopitpago.com/tarifas
        </a>
        .
      </p>

      <p>
        Las Tarifas aplicables serán descontadas del monto de cada transacción
        realizada a través del Servicio o, en su defecto, serán debitadas de la
        Cuenta Virtual. Además, LA SOCIEDAD descontará los gastos, descuentos,
        retenciones y percepciones que correspondan aplicar en base a sus
        propias políticas y a las leyes y/o normas aplicables, que serán en
        todos los casos a cargo del Usuario.
      </p>

      <p>
        Según la condición fiscal declarada por el Usuario, es probable que la
        Administración Federal de Ingresos Públicos y/o los fiscos provinciales
        apliquen retenciones y/o percepciones impositivas en los cobros
        efectuados.
      </p>

      <p>
        3.2. Los titulares de las Cuentas Virtuales Comercio, podrán recibir una
        compensación de parte de YOPIT Pago, por la venta de los servicios de
        recarga de celular, pago de servicios o impuestos (herramienta prevista
        en el artículo 2.4 v del presente) prestados por YOPIT Pago, conforme
        los valores indicados en el siguiente link{' '}
        <a href="http://www.yopitpago.com/tarifas">
          http://www.yopitpago.com/tarifas
        </a>
        .
      </p>

      <p>
        3.3. LA SOCIEDAD se reserva el derecho de modificar, cambiar, agregar, o
        eliminar las Tarifas y Comisiones vigentes, en cualquier momento,
        informando de ello a los Usuarios oportunamente.
      </p>

      <p>
        3.4. La falta de cumplimiento en las obligaciones de pago asumidas por
        los Usuarios otorgarán a LA SOCIEDAD la facultad de suspender o cancelar
        en forma definitiva el Servicio sin que ello genere derecho a reclamo
        alguno a favor del Usuario.
      </p>

      <p>
        3.5. LA SOCIEDAD, además, se reserva el derecho de tomar las medidas
        judiciales y extrajudiciales que estime pertinentes en los casos en que
        se registren deudas por parte de los Usuarios.
      </p>

      <h2 class="subtitle">
        CLAUSULA CUARTA:<u>Uso de la Aplicación</u>
      </h2>

      <p>
        4.1. LA SOCIEDAD no será responsable si el Usuario no cuenta con un
        medio de comunicación o tecnológico compatible con el uso de la
        Plataforma para el acceso a la información.
      </p>

      <p>
        4.2. El Usuario se compromete a hacer un uso adecuado y lícito de la
        Plataforma de conformidad con la legislación aplicable, los presentes
        Términos y Condiciones, la moral y buenas costumbres generalmente
        aceptadas y al orden público.
      </p>

      <p>
        4.3. Al utilizar la Plataforma o el Servicio, el Usuario acuerda que:
      </p>

      <ol class="ol-alphabetical-point">
        <li>No tendrá facultades para revender su Cuenta a un tercero.</li>
        <li>
          No publicará información inexacta en los diferentes campos de su
          perfil o cuenta,proporcionando datos reales, que se compromete a
          mantener actualizados;
        </li>
        <li>
          No autorizará a terceros a usar su Cuenta, ni creará una Cuenta para
          terceras personas físicas o jurídicas sin contar con su previa
          autorización por escrito;
        </li>
        <li>
          No cederá ni transferirá de otro modo su Cuenta a ninguna otra persona
          o entidad legal
        </li>
        <li>
          No utilizará una cuenta que esté sujeta a cualquier derecho de una
          persona que no sea ella sin la autorización adecuada.
        </li>
        <li>
          No solicitará el Servicio con fines ilícitos, ilegales, contrarios a
          lo establecido en los presentes Términos y Condiciones, a la buena fe
          y al orden público, lesivos de los derechos e intereses de terceros.
        </li>
        <li>
          No tratará de dañar el Servicio o la Plataforma de ningún modo, ni
          accederá a recursos restringidos en la Plataforma.
        </li>
        <li>
          Guardará de forma segura y confidencial la contraseña de su Cuenta y
          cualquier identificación facilitada para permitirle acceder al
          Servicio.
        </li>
        <li>
          No utilizará el Servicio o la Aplicación con un dispositivo
          incompatible o no autorizado.
        </li>
        <li>
          No subirá, publicará, enviará, transmitirá o de otro modo facilitará o
          iniciará contenidos que hagan declaraciones falsas, suplanten su
          identidad o de otro modo tergiversen la misma, sean ilegales,
          injuriosos, abusivos, obscenos o discriminatorios, incluyan
          información que no posea el derecho a divulgar o facilitar por ley o
          debido a relaciones contractuales;
        </li>
        <li>
          No subirá, publicará, enviará, transmitirá o de otro modo facilitará o
          iniciará contenidos que incluyan o contengan virus informáticos,
          gusanos, troyanos u otros códigos informáticos maliciosos o programas
          o archivos dirigidos a interrumpir, destruir o limitar la
          funcionalidad de cualquier software o hardware u orientado a recabar
          información privada de otros Usuarios o de terceros.
        </li>
        <li>
          No utilizará técnicas de ingeniería inversa, no descompilará,
          desarmará, descifrará o de otro modo tratará de obtener el código
          fuente de cualquier propiedad intelectual subyacente utilizada para la
          prestación de los Servicios o de cualquiera de sus partes;
        </li>
        <li>
          Reconoce y acepta que no tiene con LA SOCIEDAD ninguna relación más
          allá de las explícitamente indicadas en los presentes Términos y
          Condiciones.
        </li>
        <li>
          No eliminará los avisos de derechos de autor (copyright), marca
          registrada u otros derechos de propiedad intelectual o industrial que
          aparezcan en los Servicios;
        </li>
        <li>
          No eliminará, distorsionará, cubrirá o de otro modo bloqueará, total o
          parcialmente, la visión de la Plataforma, formas de publicidad o
          comunicación incluida en los contenidos o Servicios de la misma;
        </li>
        <li>
          No infringirá o utilizará la marca, logotipos y/o marcas registradas
          de LA SOCIEDAD, incluidos, entre otros, el término “Yopit Pago”, el
          logotipo, sus plug-ins en cualquier nombre comercial, correo
          electrónico o URL sin autorización expresa de LA SOCIEDAD;
        </li>
        <li>
          No utilizará software automatizado o manual, aparatos, scripts robots,
          otros procesos para acceder, “scrape,” “crawl” o “spider” a LA
          SOCIEDAD o sus Servicios.
        </li>
        <li>
          No realizar “framing”, “mirroring” ni utilizar otros medios para
          simular la apariencia o función de la Plataforma.
        </li>
      </ol>

      <h2 class="subtitle">
        CLAUSULA QUINTA:<u>Suspensión y/o cancelación de la Cuenta.</u>
      </h2>

      <p>
        5.1. Sin perjuicio de otras medidas, LA SOCIEDAD podrá advertir,
        suspender en forma temporal o definitivamente la Cuenta de un Usuario
        y/o iniciar las acciones que estime pertinentes y/o suspender la
        prestación de sus Servicios si (a) se quebrantara alguna ley, o
        cualquiera de las estipulaciones de los Términos y Condiciones Generales
        y demás políticas de LA SOCIEDAD; (b) si incumpliera sus compromisos
        como Usuario; (c) si se incurriera a criterio de LA SOCIEDAD en
        conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la
        identidad del Usuario o cualquier información proporcionada por el mismo
        fuere errónea.
      </p>

      <h2 class="subtitle">
        CLAUSULA SEXTA:
        <u>
          Supuesto de Robo, Pérdida o Destrucción del Teléfono Celular. Sitio
          Web.
        </u>
      </h2>

      <p>
        6.1. El Servicio podrá ser utilizado exclusivamente a través de la
        Plataforma. Sin perjuicio de ello, LA SOCIEDAD prevé que en el supuesto
        de robo, pérdida, destrucción del teléfono celular o cualquier otra
        situación que impida obtener el medio de comunicación o tecnológico
        adecuado para utilizar la Plataforma, el Usuario podrá a través del
        ingreso al Sitio, la posibilidad de informar la situación en la que se
        encuentra, retirar el saldo pendiente que el Usuario posea en la Cuenta
        Virtual a través de los métodos ofrecidos por LA SOCIEDAD y así,
        solicitar la baja de la Cuenta.
      </p>

      <h2 class="subtitle">
        CLAUSULA SÉPTIMA:
        <u>Responsabilidad</u>
      </h2>

      <p>
        7.1.LA SOCIEDAD sólo pone a disposición de los Usuarios una Plataforma a
        fin de facilitar sus operaciones comerciales de acuerdo a las
        disposiciones de estos Términos y Condiciones. LA SOCIEDAD simplemente
        se limita a realizar las operaciones comerciales como pagos o
        transferencias. El Usuario es el único responsable por la legitimidad y
        veracidad de los Datos Personales, como también de los datos registrados
        de terceros.
      </p>

      <p>
        7.2. LA SOCIEDAD no será responsable en caso de robo, ruptura o pérdida
        del teléfono celular del Usuario. En cualquiera los supuestos
        mencionados previamente será el Usuario, quien tendrá responsabilidad
        exclusiva en caso de retiro del dinero de la Aplicación. El sistema
        informático de la Plataforma utilizará parámetros determinados para
        verificar y corroborar sobre aquellas Cuentas que se encuentren
        realizando movimientos inusuales. En caso de que ello suceda, LA
        SOCIEDAD solicitará al Usuario la contraseña para nuevas operaciones que
        tenga intenciones de realizar.
      </p>

      <p>
        7.3. El Usuario conoce y acepta que al realizar operaciones en la
        Plataforma lo hace bajo su propio riesgo. En ningún caso LA SOCIEDAD
        será responsable por lucro cesante, o por cualquier otro daño y/o
        perjuicio que haya podido sufrir el Usuario, debido al Servicio
        prestado.
      </p>

      <p>
        7.4. LA SOCIEDAD como proveedor de servicios de pago (“PSP”) ofrece
        servicios de pago y no se encuentra autorizado a operar como entidad
        financiera por el Banco Central de la República Argentina. LA SOCIEDAD
        no presta al Usuario ningún servicio bancario o cambiario. LA SOCIEDAD,
        entre otros Servicios, brinda un servicio de procesamiento de pagos por
        cuenta y orden de los Usuarios según las condiciones establecidas en
        estos Términos y Condiciones y en la normativa aplicable. Los fondos
        depositados en la Cuenta no constituyen depósitos en una entidad
        financiera, ni cuentan con ninguna de las garantías que tales depósitos
        puedan gozar de acuerdo con la legislación y reglamentación aplicables
        en materia de depósitos de entidades financieras.
      </p>

      <h2 class="subtitle">
        CLAUSULA OCTAVA:
        <u>Uso y Garantía de la Plataforma</u>
      </h2>

      <p>
        8.1. LA SOCIEDAD no garantiza la disponibilidad y continuidad del
        funcionamiento de la Plataforma. En consecuencia, LA SOCIEDAD no será en
        ningún caso responsable por cualesquiera daños y perjuicios que puedan
        derivarse de (i) la falta de disponibilidad o accesibilidad a la
        Plataforma; (ii) la interrupción en el funcionamiento de la Plataforma o
        fallos informáticos, averías telefónicas, desconexiones, retrasos o
        bloqueos causados por deficiencias o sobrecargas en las líneas
        telefónicas, centros de datos, en los sistemas de comunicación, Internet
        o en otros sistemas electrónicos, producidos en el curso de su
        funcionamiento; y (iii) otros daños que puedan ser causados por terceros
        mediante intromisiones no autorizadas ajenas al control de LA
        SOCIEDAD.Los Usuarios NO podrán imputar responsabilidad alguna a LA
        SOCIEDAD ni exigir pago por lucro cesante, en virtud de perjuicios
        resultantes de dificultades técnicas o fallas en los sistemas o en
        Internet. El sistema puede eventualmente no estar disponible debido a
        dificultades técnicas o fallas de Internet, o por cualquier otra
        circunstancia ajena a LA SOCIEDAD; en tales casos se procurará
        restablecerlo con la mayor celeridad posible sin que por ello pueda
        imputársele algún tipo de responsabilidad.
      </p>

      <p>
        8.2. LA SOCIEDAD no garantiza la ausencia de virus ni de otros elementos
        en la Plataforma introducidos por terceros ajenos a LA SOCIEDAD que
        puedan producir alteraciones en los sistemas físicos o lógicos del
        Usuario o en los documentos electrónicos y archivos almacenados en sus
        sistemas. En consecuencia, LA SOCIEDAD no será en ningún caso
        responsable de cualesquiera daños y perjuicios de toda naturaleza que
        pudieran derivarse de la presencia de virus u otros elementos que puedan
        producir alteraciones en los sistemas físicos o lógicos, documentos
        electrónicos o ficheros del Usuario.
      </p>

      <p>
        8.3. LA SOCIEDAD adopta diversas medidas de protección para proteger la
        Plataforma y los contenidos contra ataques informáticos de terceros. No
        obstante, LA SOCIEDAD no garantiza que terceros no autorizados no puedan
        conocer las condiciones, características y circunstancias en las cuales
        el Usuario o cualquier otro tipo de usuario accede a la Aplicación. En
        consecuencia, LA SOCIEDAD no será en ningún caso responsable de los
        daños y perjuicios que pudieran derivarse de dicho acceso no autorizado.
      </p>

      <p>
        8.4. Con la aceptación de los presentes Términos y Condiciones, el
        Usuario declara que mantendrá indemne frente a cualquier reclamo a LA
        SOCIEDAD, sus empresas controladas y controlantes, directores, socios,
        empleados, abogados y agentes, derivado del (i) incumplimiento por parte
        de los Usuarios o de cualquier otro usuario de cualquier disposición
        contenida los presentes en los Términos y Condiciones o de cualquier ley
        o regulación aplicable a las mismas, (ii) incumplimiento o violación de
        los derechos de terceros incluyendo, a título meramente enunciativo,
        otros Usuarios u otro tipo de usuarios; y (iii) incumplimiento del uso
        permitido de la Plataforma.
      </p>

      <p>
        8.5. En ningún caso LA SOCIEDAD, ni sus accionistas, proveedores o
        distribuidores serán responsables de la pérdida de beneficios, de
        ingresos o datos, ni de pérdidas financieras, ni de daños indirectos,
        especiales, derivados, ejemplares, ni punitivos, que pudieren sufrir o
        alegar los Usuarios.
      </p>

      <h2 class="subtitle">
        CLAUSULA NOVENA:
        <u>Derechos de Propiedad Intelectual e Industrial</u>
      </h2>

      <p>
        9.1. El Usuario, así como cualquier otro tipo de usuario del que se
        trate, reconoce y acepta que todos los derechos de propiedad intelectual
        e industrial sobre los contenidos y/o cualesquiera otros elementos
        insertados en la Plataforma (incluyendo, sin limitación, marcas,
        logotipos, nombres comerciales, textos, imágenes, gráficos, diseños,
        sonidos, bases de datos, software, APIs, diagramas de flujo,
        presentación, audio y vídeo), pertenecen a LA SOCIEDAD.
      </p>

      <p>
        9.2. LA SOCIEDAD autoriza al Usuario, y durante la vigencia del
        Servicio, a utilizar, visualizar, imprimir, descargar y almacenar los
        contenidos y/o los elementos insertados en la Plataforma exclusivamente
        para su uso personal, privado y no lucrativo, absteniéndose de realizar
        sobre los mismos cualquier acto de descompilación, ingeniería inversa,
        modificación, divulgación o suministro. Cualquier otro uso o explotación
        de cualesquiera contenidos y/u otros elementos insertados en la
        Plataforma distinto de los aquí expresamente previstos estará sujeto a
        la autorización previa de LA SOCIEDAD.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA:
        <u>Protección de Datos</u>
      </h2>

      <p>
        10.1. Los Datos Personales que el Usuario proporciona en el Registro se
        integrarán en una base de datos personales de la que es responsable LA
        SOCIEDAD.
      </p>

      <p>
        10.2. LA SOCIEDAD declara que los Datos Personales de los Usuarios,
        serán utilizados únicamente con el fin del registro mencionado en la
        CLAUSULA PRIMERA y a efectos de la prestación del Servicio.
      </p>

      <p>
        10.3. Los Usuarios podrán ejercitar los derechos de acceder, rectificar,
        suprimir y actualizar su Información Personal, así como a oponerse al
        tratamiento de la misma, todo ello de conformidad a lo dispuesto en la
        normativa aplicable (Ley 25.326 - Ley de Protección de Datos
        Personales).
      </p>

      <p>
        10.4. El Usuario reconoce y acepta que LA SOCIEDAD puede revelar a
        terceras partes, de forma anónima, cierto conjunto de los datos
        suministrados por el Usuario para la utilización de los Servicios. LA
        SOCIEDAD no revelará a terceras partes sus datos personales sin su
        consentimiento previo, excepto en la medida en que sea necesario para el
        cumplimiento de las leyes y/o procedimientos legales vigentes, donde tal
        información sea relevante. LA SOCIEDAD se reserva el derecho de
        ofrecerle servicios y productos de terceros basados en las preferencias
        que el Usuario haya indicado al momento de registrarse y/o en cualquier
        momento posterior; tales ofertas pueden efectuarse por LA SOCIEDAD o por
        terceros.
      </p>

      <p>
        10.5. LA SOCIEDAD, se compromete a garantizar el cumplimiento de lo
        establecido en la ley 25.326 y el decreto 1558/2001 respecto de la
        Protección de los Datos Personales, de los usuarios y/o de cualquier
        tercero, así como de garantizar los derechos conferidos en la normativa
        mencionada.
      </p>

      <p>
        10.6. Las cuestiones relacionadas con la protección de los datos
        personales vinculadas con el Servicio serán contempladas en las
        Políticas de Privacidad publicadas en el siguiente enlace:{' '}
        <a href="http://www.yopitpago.com/politicadeprivacidad">
          http://www.yopitpago.com/politicadeprivacidad
        </a>
        , las que forman parte integral de los presentes Términos y Condiciones.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA PRIMERA:
        <u>Integridad</u>
      </h2>

      <p>
        La eventual invalidez o nulidad de alguna o algunas de las cláusulas del
        presente contrato no implica la invalidez de las restantes cláusulas ni
        de los principios de buena fe contractual que las partes verosímilmente
        entendieron o debieron entender actuando con cuidado y previsión.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA SEGUNDA:
        <u>Irrenunciabilidad de Derechos.</u>
      </h2>

      <p>
        Si el Usuario no cumple con los presentes Términos y Condiciones, y LA
        SOCIEDAD no toma medida al respecto de forma inmediata, no se entenderá
        que haya renunciado al ejercicio de los derechos de los que puede
        disponer.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA TERCERA:
        <u>Modificaciones a los Términos y Condiciones.</u>
      </h2>

      <p>
        LA SOCIEDAD se reserva el derecho de modificar, complementar o
        reemplazar los presentes Términos y Condiciones, en cualquier momento,
        haciendo públicos en la Plataforma los términos modificados, sin
        necesidad de dar previo aviso a los Usuarios.
      </p>

      <p>
        Las modificaciones entrarán en vigor en un plazo no inferior a 24 horas
        a partir de la fecha de su incorporación en la Plataforma. No obstante,
        las modificaciones que afecten a nuevas funciones de un Servicio o los
        cambios que se hagan por cuestiones legales entrarán en vigor de forma
        inmediata.
      </p>

      <p>
        Si el Usuario no acepta las condiciones modificadas de un Servicio,
        deberá dejar de usar dicho Servicio o solicitar la baja de la Cuenta.
      </p>

      <p>
        Asimismo, dada la posibilidad de modificación del contenido de la
        Plataforma, como así también la incorporación de nuevos servicios por
        medio de actualizaciones de la misma, es posible que el Usuario deba
        aplicar y aceptar condiciones adicionales o particulares (como, por
        ejemplo, restricciones de edad).
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA CUARTA:
        <u>Finalización del contrato.</u>
      </h2>

      <p>
        Las disposiciones contenidas en el presente serán de aplicación hasta el
        momento en que el Usuario o LA SOCIEDAD pongan fin a las mismas de
        conformidad con lo establecido a continuación.
      </p>

      <p>
        El Usuario puede poner fin al contrato con LA SOCIEDAD en cualquier
        momento, por cualquier razón, con la sola desactivación de su cuenta y/o
        poniendo fin al uso de los Servicios de LA SOCIEDAD. No es necesario que
        notifique expresamente a LA SOCIEDAD el momento en que pone fin al uso
        de los Servicios.
      </p>

      <p>
        Por su parte, LA SOCIEDAD puede poner en suspenso o bien que poner fin
        al uso de su cuenta o bien dejar de proveer alguno, todos o parte de los
        Servicios en cualquier momento y por cualquier razón, incluyendo, y sin
        limitación alguna, aquellos casos en los que razonablemente se estime
        que: (i) el Usuario haya infringido las disposiciones contenidas en el
        presente contrato, o las leyes vigentes y aplicables; (ii) genere a LA
        SOCIEDAD cierto riesgo o exposición legal; o (iii) porque LA SOCIEDAD lo
        considere así pertinente según su criterio. LA SOCIEDAD hará los
        esfuerzos que sean razonables para notificar al Usuario este hecho a
        través del correo electrónico vinculado a su cuenta o bien en el momento
        en el que intente acceder a su cuenta de nuevo.
      </p>

      <p>
        Nada de lo dispuesto en esta cláusula afecta al derecho de LA SOCIEDAD
        para modificar, limitar o dejar de prestar los Servicios sin que medie
        notificación previa.
      </p>

      <p>
        LA SOCIEDAD retiene para sí el derecho de admisión y permanencia de
        cualquier Usuario, cuando por razones objetivas considere conveniente la
        no admisión o permanencia de alguno de los Usuarios en la Plataforma,
        sin que sea necesario al efecto expedirse acerca de las razones que
        motivaron tal decisión.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA QUINTA:
        <u>Notificaciones</u>
      </h2>

      <p>
        15.1. LA SOCIEDAD podrá realizar las notificaciones oportunas al Usuario
        a través de una notificación general en la Plataforma, a través de
        mensajes de texto, la dirección de correo electrónico facilitada por el
        Usuario en su Cuenta. El Usuario podrá notificar a LA SOCIEDAD mediante
        el envío de un correo electrónico a la dirección info@yopitpago.com.
      </p>

      <p>
        15.2. LA SOCIEDAD se reserva la posibilidad de enviar notificaciones a
        los Usuarios registrados a fin de informarle sobre (i) la acreditación
        del Pago; (ii) publicidades; y (iii) cualquier otra cuestión que LA
        SOCIEDAD considere oportuna (tales como, pero no limitadas a, las
        “Notificaciones Push”).
      </p>

      <p>
        Las Notificaciones Push servirán a LA SOCIEDAD para mantener la
        comunicación con el Usuario. Sin perjuicio de ello, el Usuario podrá
        desactivar las Notificaciones Push, pero al hacerlo deberá mantener
        indemne a LA SOCIEDAD de cualquier tipo de reclamo por cualquier
        notificación importante que deje de recibir como consecuencia de haber
        desactivado las Notificaciones Push.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA SEXTA:
        <u>Cesión</u>
      </h2>

      <p>
        16.1. El Usuario no podrá ceder sus derechos y obligaciones emanadas de
        los presentes Términos y Condiciones sin el previo consentimiento por
        escrito de LA SOCIEDAD. LA SOCIEDAD podrá ceder, sin necesidad de
        recabar el consentimiento previo del Usuario, los presentes Términos y
        Condiciones a cualquier entidad comprendida dentro de su grupo de
        sociedades, en todo el mundo, así como a cualquier persona o entidad que
        le suceda en el ejercicio de su negocio por cualesquiera títulos.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA SÉPTIMA:
        <u>Independencia</u>
      </h2>

      <p>
        El Usuario y LA SOCIEDAD son contratistas independientes, sin ninguna
        relación societaria o comercial fuera de los presentes Términos y
        Condiciones, y cada una de ellos mantendrá indemne al otro por cualquier
        reclamo derivado de las obligaciones impositivas, laborales y/o
        previsionales que estuvieran a su cargo. Ninguna de las partes inducirá
        a error a persona alguna sobre su carácter de parte contractual
        independiente de la otra ni obligará a la otra parte frente a terceros.
        El Usuario declara expresamente que cuentan con la experiencia,
        solvencia económica, equipamiento, personal, infraestructura, permisos,
        autorizaciones y habilitaciones que resulten necesarios, para la debida
        concreción de las obligaciones a su cargo. El incumplimiento de estas
        obligaciones por el Usuario habilitará a SOCIEDAD a rescindir el
        presente acuerdo de pleno derecho en cualquier momento.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA OCTAVA:
        <u>Proveedor de Servicios de Pago</u>
      </h2>

      <p>
        LA SOCIEDAD es proveedor de servicios de pago en los términos de la
        Comunicación “A” 6885 del Banco Central de la República. LA SOCIEDAD
        ofrece servicios de pago y no se encuentra autorizado a operar como
        entidad financiera por el Banco Central de la República Argentina. Los
        fondos depositados en la cuenta de pago no constituyen depósitos en una
        entidad financiera, ni cuentan con ninguna de las garantías que tales
        depósitos puedan gozar de acuerdo con la legislación y reglamentación
        aplicables en materia de depósitos de entidades financieras.
      </p>

      <h2 class="subtitle">
        CLAUSULA DÉCIMA NOVENA:
        <u>Ley Aplicable y Jurisdicción</u>
      </h2>

      <p>
        17.1. Los presentes Términos y Condiciones, así como la relación entre
        LA SOCIEDAD y el Usuario, se regirán e interpretarán con arreglo a la
        legislación vigente en la República Argentina.
      </p>

      <p>
        17.2. Ante cualquier controversia vinculada al cumplimiento o
        interpretación de los presentes Términos y Condiciones, las Partes
        acuerdan someterla a los Tribunales ordinarios de la Ciudad Autónoma de
        Buenos Aires, República Argentina, renunciando a cualquier fuero de
        excepción que pudiera corresponder.A todos los efectos emergentes de
        estos Términos y Condiciones, LA SOCIEDAD constituye domicilio en Godoy
        Cruz 1642, depto. 3, Ciudad Autónoma de Buenos Aires, República
        Argentina.
      </p>

      <p className="footer-text">Última actualización 11/05/2023 </p>
    </div>
  );
};

export default TerminosYCondiciones;
