import { Link, NavLink, Outlet } from 'react-router-dom';
import './../../main.scss';
import './Tarifas.scss';

function Tarifas() {
  return (
    <div id="tarifas">
      <header>
        <div className="tarifasHeader">
          <div className="tarifasHeaderTitle">
            <Link to="/" className="volver cta">
              ←Volver
            </Link>
            <h1>Tarifas</h1>
          </div>
          <img src="./../assets/logo.svg" alt="Yopit Pago" />
        </div>
      </header>
      <nav>
        <ul id="buttons">
          <NavLink to="qr">
            <li>Cobro con QR</li>
          </NavLink>
          <NavLink to="transferencia">
            <li>Transferencias CVU y CBU</li>
          </NavLink>
          <NavLink to="pos">
            <li>Terminal MPOS</li>
          </NavLink>
          <NavLink to="rechargeandservices">
            <li>Pago de Servicios y Recargas</li>
          </NavLink>
          <NavLink to="debito">
            <li>Tarjetas de Débito</li>
          </NavLink>
          <NavLink to="extracash">
            <li>Retiro de Efectivo</li>
          </NavLink>
          
          {/* <NavLink to="credito">
            <li>Tarjetas de Crédito</li>
          </NavLink>

          <NavLink to="alimentar">
            <li>Tarjeta AlimentAR</li>
          </NavLink>
          <NavLink to="recibosdigitales">
            <li>Recibos digitales</li>
          </NavLink>
          <NavLink to="linkdepago">
            <li>Link de pago</li>
          </NavLink> */}
        </ul>
      </nav>
      <section id="tarifasContent">
        <Outlet />
      </section>
    </div>
  );
}

export default Tarifas;
