function Debito() {
  return (
    <>
      <article>
        <h2>
          Tarjetas de <strong>Débito</strong>
        </h2>
        <p>
          La solución ideal para que puedas aceptar pagos con tarjetas de débito
          de todos los bancos. Con nuestro lector, podrás ofrecer un medio de
          pago más, sin demoras ni complicaciones.
        </p>

        <h3>Tarifas</h3>
        <table>
          <thead>
            <tr>
              <th>Pagás</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3,0% + IVA</td>
            </tr>
          </tbody>
        </table>

        <h3>Beneficios</h3>
        <p>
          Aceptando tarjetas de débito, podrás ofrecerles más opciones de pago y
          recibir la acreditación de las ventas de forma inmediata.
        </p>
      </article>
      <article className="tarImage">
        <img src="../assets/tarifas-01.png" alt="" />
      </article>
    </>
  );
}

export default Debito;
